<template>
  <div class="landing">
    <h1>S  T  E  L  L  A  R  V  A  L  L  E  Y</h1>
    <h4>N D W S O F T W A R E . N L</h4>
    <div class="links">
    <router-link to="/dashboard">Go to Dashboard</router-link><br>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage"
}
</script>

<style scoped>
/* Add some styling if needed */
.landing {
  padding: 80px;
  text-align: center;
  background: #000000;
  color: white;
  font-size: 30px;
}
.links {
  font-size: 10px;
  color: #42b983;
  position: absolute;
  top: 2px;
  right: 5px;
}
p {
  font-style: italic;
}

h1 {
    font-size: 2.5em; /* Just an example size, adjust as necessary */
  font-style: italic;
}
h4 {
    font-size: 1.5em; /* Just an example size, adjust as necessary */
  font-style: italic;
}

/* Media query for screens with max-width of 768px (usually mobile devices) */
@media (max-width: 768px) {
    h1 {
        font-size: 0.8em; /* Adjust this size for mobile as needed */
      font-style: italic;
    }
  h4 {
      font-size: 0.4em; /* Adjust this size for mobile as needed */
    font-style: italic;
  }
}
</style>
